import React, { useState, useEffect } from 'react'
import "./static/css/ModalMayoristas.css"
import "animate.css"

const ModalMayoristas = ({

}) => {

  const [activado, SetActivado] = useState(true);

  const cambiarState = () => {
    SetActivado(false);
  }

  return (
    <>
      {activado ? (
        <div className='modal-main animated animate__backInDown'>
          <div className='content-modal'>
            <div className='header-modal'>
              <button
                onClick={cambiarState}
              ><strong>X</strong>
              </button>
            </div>
            <div className='content-promo'>
              <h2>¡Mayoristas!</h2>
              <p>A partir de 6 unidades, accede a descuentos especiales en todas tus compras.</p>
              <p>¡No te lo pierdas!</p>
            </div>
          </div>
          </div>
      ) : null}
    </>
  );
}

export default ModalMayoristas;