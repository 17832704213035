//Zona de importacion de componentes externos.
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
//Zona de importacion de componentes internos.
import ListaProductos from "./ListaProductos";
import { useParams } from "react-router";
import Footer from "../Footer/Footer";
import CarouselInfo from "../Index/CarouselInfo";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";
import CompraRapida from "./components/ModalCompra";
import HandlePage from "../utils/HandlePage";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";
import { actualizarEventId } from "../../global/functions";

import "./static/css/Mayoristas/Mayoristas.css";
import ModalMayoristas from "../Modal/ModalMayoristas";

const Mayoristas = ({
  ViewDetalle,
  headerState,
  modificarHeaderState,
  filtrosState,
  modificarFiltrosState,
  productosState,
  modificarProductosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  carrito,
  modificarCarritoState,
  cliente,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  // clienteState,
  // modificarClienteState,
  categoriasHombreState,
  modificarCategoriasHombreState,
  categoriasMujerState,
  modificarCategoriasMujerState,
  CatalogoState,
  actualizarSessionStorage,
  rutaDinamica,
  vendedor,
  modificarVendedorState,
  Bodega,
  setBodega,
}) => {
  const { clienteState, modificarClienteState } = useGlobalContext();

  /** Envia el evento de visita a la pagina web de mayoristas a facebook */
  const enviarEventoFacebook = async () => {
    const event_id = actualizarEventId();
    const userIP = await apiFacebook.get();
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `hmay-${event_id}`,
      event_name: "HomeMayoristas",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0"
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  /**Productos agrpados por referencia */
  const [productosByRef, setProductosByRef] = useState([]);

  /** Modal de compra rapida */
  const [activado, setActivado] = useState(false);

  /** Producto seleccionado para mostrar en modal */
  const [productoCompra, setProductoCompra] = useState(null)

  let { Categoria, Genero, Subgenero, Marca } = useParams();
  //Referencia para el primer producto.
  // const barCamisetasHombre = useRef(null);
  // const barCamisetasHombreBasicas = useRef(null);
  // const barCamisetasHombreEstampadas = useRef(null);
  // const barCamisetasHombreValtimor = useRef(null);
  // const barHoodiesHombre = useRef(null);
  // const barJoggersHombre = useRef(null);
  // const barPantalonetasHombre = useRef(null);
  // const barPolosHombre = useRef(null);
  // const barGorras = useRef(null);
  // const barCamisetasMujer = useRef(null);
  // const barHoodiesMujer = useRef(null);
  // const barJoggersMujer = useRef(null);
  // const barCamisas = useRef(null);
  // const barDescuentos = useRef(null);

  const barBlusas = useRef(null);
  const barTops = useRef(null);
  const barSets = useRef(null);
  const barLycras = useRef(null); // Bikers
  const barVestidos = useRef(null);
  const barEnterizos = useRef(null);
  const barBodys = useRef(null);
  const barRopaInterior = useRef(null);

  // Creamos un state para cada Bar de categoria.
  const [estadoBar, actualizarEstadoBar] = useState({
    listaBlusas: false,
    listaTops: false,
    listaSets: false,
    listaLycras: false, // Bikers
    listaVestidos: false,
    listaBikers: false,
    listaEnterizos: false,
    listaBodys: false,
    listaRopaInterior: false,
  });

  const [data, actualizarData] = useState({
    // GORRAS: { Nuevo: false, Descuento: 0 },
    // BASICAS: { Nuevo: false, Descuento: 0 },
    // ESTAMPADAS: { Nuevo: false, Descuento: 0 },
    // VALTIMOR: { Nuevo: false, Descuento: 0 },
    // MUJER: { Nuevo: false, Descuento: 0 },
    // POLOS: { Nuevo: false, Descuento: 0 },
    BLUSAS: { Nuevo: false, Descuento: 0, Disponible: true },
    TOPS: { Nuevo: false, Descuento: 0, Disponible: true },
    SETS: { Nuevo: false, Descuento: 0, Disponible: true },
    LYCRAS: { Nuevo: false, Descuento: 0, Disponible: true },
    VESTIDOS: { Nuevo: false, Descuento: 0, Disponible: true },
    ENTERIZOS: { Nuevo: false, Descuento: 0, Disponible: true },
    BODYS: { Nuevo: false, Descuento: 0, Disponible: true },
    ROPAINTERIOR: { Nuevo: false, Descuento: 0, Disponible: true },
    // CAMISAS: { Nuevo: false, Descuento: 0 },
    // PANTALONETAS: { Nuevo: false, Descuento: 0 },
    // HOODIES_H: { Nuevo: false, Descuento: 0 },
    // HODDIES_M: { Nuevo: false, Descuento: 0 },
    // JOGGER_H: { Nuevo: false, Descuento: 0 },
    // JOGGER_M: { Nuevo: false, Descuento: 0 },
  });

  //ReactPixel.track("HomeMayoristas", "ViewContent");
  //Se hace una consulta a la api para obtener los productos segun la seccion ingresada por parametro en la ruta '/Inventario/ConsultarProductos/{Categoria}/{Genero}'
  const obtenerProductos = async (
    refBar,
    categoriaId,
    generoId,
    marcaId,
    subgeneroId,
    marcaExcluida
  ) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }
    let url = apiUrlState + "/Api/Inventario/GetProductos";
    let data = {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "",
      ProductosNuevos: false,
      Descuentos: false,
      Todo: false,
      Origen: "Mayorista"
    };
    let i;
    const categoriaALlamar = categoriaId;
    
    const marcaALlamar = marcaId;
    const SubgeneroALlamar = subgeneroId;
    const MarcaExcluida = marcaExcluida;
    if (categoriaALlamar === "Descuentos") {
      data["Descuentos"] = true;
    } else if (categoriaALlamar !== null) {
      if (MarcaExcluida === "607ed64c521b1c0910a581b3") {
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b4" });
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b5" });
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b6" });
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b7" });
      }
      if (marcaALlamar !== null) {
        data["Marcas"].push({ Marca: marcaALlamar });
      }
      if (SubgeneroALlamar !== null) {
        data["Subcategoria"].push({ Subcategoria: SubgeneroALlamar });
      }
      // data["Categorias"].push({ Categoria: categoriaALlamar });
      // Mostrar blusas dk en categoria camiseta mujer
      if (categoriaALlamar === "607ed63e521b1c0910a581aa" && generoId === "607ed653521b1c0910a581b9" ){
        data["Categorias"].push({ Categoria: categoriaALlamar });
        // data["Categorias"].push({ Categoria: "651aec4251072238a0969749" });
      } else {
        data["Categorias"].push({ Categoria: categoriaALlamar });
      }
      // end mostrar blusas dk en categoria camiseta mujer
      if (categoriaALlamar !== "607ed63e521b1c0910a581ac")
        data["Generos"].push({ Genero: generoId });
    } else data["ProductosNuevos"] = true;

    for (i = 0; i < marcasState.length; i++) {
      data["Marcas"].push({ Marca: marcasState[i] });
    }
    for (i = 0; i < tallasState.length; i++) {
      data["Tallas"].push({ "Variantes.Tallas.Talla": tallasState[i] });
    }
    for (i = 0; i < coloresState.length; i++) {
      data["Colores"].push({ "Variantes.Color": coloresState[i] });
    }
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }

    await axios
      .post(
        url,
        {
          data,
        },
        {}
      )
      .then((response) => {
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
        //refBar.current.scrollIntoView({behavior: "smooth"});
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: false },
          [],
        ]);
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const obtenerData = async () => {
    let url = apiUrlState + "/Api/Inventario/getData";
    await axios.get(url).then((response) => {
      actualizarData(response.data[0]);
    });
  };
  //Limpia los states de todos los filtros para no dejar ninguno seleccionado.
  const limpiarFiltros = () => {
    if (tallasState.length > 0) modificarTallasState([]);
    if (coloresState.length > 0) modificarColoresState([]);
    if (marcasState.length > 0) modificarMarcasState([]);
  };

  //Funcion para actualizar el estado de cada lista de productos.
  const actualizarLista = (
    queBar,
    categoriaId,
    generoId,
    refBar,
    MarcaId,
    SubgeneroId,
    MarcaExcluida
  ) => {
    // if (queBar === "listaGorras") {
    //   ReactPixel.track("Gorras", "ViewContent");
    // }
    // if (queBar === "listaPolosHombre") {
    //   ReactPixel.track("Polos", "ViewContent");
    // }
    // if (queBar === "listaCamisetasMujer") {
    //   ReactPixel.track("Camisetas Mujer", "ViewContent");
    // }
    // if (queBar === "listaHoodiesHombre") {
    //   ReactPixel.track("Hoodies Hombre", "ViewContent");
    // }
    // if (queBar === "listaHoodiesMujer") {
    //   ReactPixel.track("Hoodies Mujer", "ViewContent");
    // }
    // if (queBar === "listaPantalonetasHombre") {
    //   ReactPixel.track("Pantalonetas", "ViewContent");
    // }
    // if (queBar === "listaJoggersHombre") {
    //   ReactPixel.track("Joggers Hombre", "ViewContent");
    // }
    // if (queBar === "listaJoggersMujer") {
    //   ReactPixel.track("Joggers Mujer", "ViewContent");
    // }
    // if (queBar === "listaCamisetasHombreBasicas") {
    //   ReactPixel.track("Camisetas Basicas", "ViewContent");
    // }
    // if (queBar === "listaCamisetasHombreEstampadas") {
    //   ReactPixel.track("Camisetas Estampadas", "ViewContent");
    // }
    // if (queBar === "listaCamisetasHombreValtimor") {
    //   ReactPixel.track("Camisetas Valtimor", "ViewContent");
    // }

    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    limpiarFiltros();
    if (!estadoBar[queBar]) {
      obtenerProductos(
        refBar,
        categoriaId,
        generoId,
        MarcaId,
        SubgeneroId,
        MarcaExcluida
      );
    }
    let estados = {
    listaBlusas: false,
    listaTops: false,
    listaSets: false,
    listaLycras: false, // Bikers
    listaVestidos: false,
    listaBikers: false,
    listaEnterizos: false,
    listaBodys: false,
    listaRopaInterior: false,
    // listaDescuentos: false,
    };
    estados[queBar] = !estadoBar[queBar];
    actualizarEstadoBar(estados);
  };

  //Ruta landing
  const RutaLanding = async () => {
    //rutaDinamica = false
    if (Categoria === "Blusa") {
      actualizarLista(
        "listaBlusas",
        "6583763e8dfd4f911a2d0388",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barBlusas
      );
    }
    if (Categoria === "Top") {
      actualizarLista(
        "listaTops",
        "6584546244954018ca00865e",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barTops
      );
    }
    if (Categoria === "Set") {
      actualizarLista(
        "listaSets",
        "6584551544954018ca008661",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barSets
      );
    }
    if (Categoria === "Lycra") {
      actualizarLista(
        "listaLycras",
        "6525a7906b44bb8bf3323210",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barLycras
      );
    }
    if (Categoria === "Vestido") {
      actualizarLista(
        "listaVestidos",
        "65578d4701bf5211b63d14bc",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barVestidos
      );
    }
    if (Categoria === "Enterizo") {
      actualizarLista(
        "listaEnterizos",
        "65f075f9f379953d314aeca3",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barEnterizos
      );
    }
    if (Categoria === "Body") {
      actualizarLista(
        "listaBodys",
        "655bad5a1cdc932b9e974a01",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barBodys
      );
    }
    if (Categoria === "Ropa Interior") {
      actualizarLista(
        "listaRopaInterior",
        "65f06ae2f379953d314aeca2",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barRopaInterior
      );
    }
  };

  /** Agrupa los productos por referencia */
  const groupBy = (array, keyFunction) => {
    return array.reduce((result, item) => {
      const key = keyFunction(item);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});
  }

  useEffect(() => {
    if(productosState[1].length > 0) {
      const pAgrupados = groupBy(productosState[1], ({ RefBase }) => RefBase);
      setProductosByRef(pAgrupados);
    }  
  }, [productosState])

  useEffect(() => {
    var header = JSON.parse(JSON.stringify(headerState));

    if (headerState["displayMenu"] === "Index") {
      modificarFiltrosState({
        Genero: filtrosState["Genero"],
        Categoria: filtrosState["Categoria"],
        CambioFiltro: false,
        CambioCategoria: false,
        filtroResumido: false,
        posicionFiltros: "-100%",
      });
      const tipoClienteSession = sessionStorage.TipoCliente;
      modificarHeaderState({
        srcLogo: headerState["srcLogo"],
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha: headerState["srcFlecha"],
        displayBuscar: "in-line",
        displayMenu: "Productos",
        color: "negro",
        Seccion: headerState["Seccion"],
        Cliente: tipoClienteSession,
        BotonCatalogo: CatalogoState,
      });
    }
    if (headerState["Cliente"] === undefined) {
      modificarHeaderState({
        srcLogo: headerState["srcLogo"],
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha: headerState["srcFlecha"],
        displayBuscar: "in-line",
        displayMenu: "Productos",
        color: "negro",
        Seccion: headerState["Seccion"],
        Cliente: "Mayoristas",
        BotonCatalogo: CatalogoState,
      });
      actualizarSessionStorage("TipoCliente", "Mayoristas");
      if (header["displayBuscar"] !== "in-line") {
        header["displayBuscar"] = "in-line";
        modificarHeaderState(header);
      }
      RutaLanding();
    }
    if (clienteState["Registro"]) {
      actualizarSessionStorage(
        "Cliente",
        JSON.stringify({
          ...clienteState,
          Registro: false,
        })
      );
      modificarClienteState({
        ...clienteState,
        Registro: false,
      });
    }
  });
  useEffect(() => {
    textoCirculo();
    obtenerData();
  }, []);
  const textoCirculo = () => {
    const circulo = document.getElementById("circle");
    const circleArray = circulo.textContent.trimStart().split("");
    circulo.textContent = " ";
    for (var i = 0; i < circleArray.length; i++) {
      circulo.innerHTML +=
        '<span style="transform:rotate(' +
        (i - 8) * 12 +
        'deg)">' +
        circleArray[i] +
        "</span>";
    }
  };

  useEffect(() => {
    enviarEventoFacebook();
   }, [])

  const images = {
    img1: [
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%203.1.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%203.2.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%203.3.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%203.4.webp",
    ],
    img2: [
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%204.1.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%204.2.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%204.3.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%204.4.webp",
    ],
    img3: [
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%205.1.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%205.2.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%205.3.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%205.4.webp",
    ],
     img4: [
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%206.1.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%206.2.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%206.3.webp",
      "https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/BANNER%206.4.webp",
    ]

  }
  /*
    //Mediante la referenca del primer producto se desplaza el scroll de forma suave hacia el.
    const irInicio = () => {
        if(prod1["current"] !== null){
            prod1.current.scrollIntoView({behavior: "smooth"});
            modificarProductosState([{"CambioSeccion": false, "Busqueda": productosState[0]["Busqueda"], "irInicio": false}, productosState[1]]);
        }
    }*/
  return (
    <>
    <ModalMayoristas />
    <CompraRapida
      apiUrlState={apiUrlState}
      productoCompra={productoCompra}
      setProductoCompra={setProductoCompra}
      activado={activado}
      setActivado={setActivado}
      carrito={carrito}
      modificarCarritoState={modificarCarritoState}
      actualizarSessionStorage={actualizarSessionStorage}
      spinnerState={spinnerState}
      modificarSpinnerState={modificarSpinnerState}
      errorState={errorState}
      modificarErrorState={modificarErrorState}
    />
    <div className="MercadoMayoristas">
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        href="https://api.whatsapp.com/send?phone=573195123051&text=Hola 👋🏻 %0D%0AEstoy viendo sus productos en la página web para mayoristas y tengo algunas dudas."
        className="float"
        target="_blank"
      >
        <i className="fa fa-whatsapp my-float"></i>
        <h1 id="circle">¿Necesitas ayuda?</h1>
      </a>
      {/* <div className="mensajeAnuncio" style={{ marginTop: "50px" }}>
        <p className="FuenteHelveticaBold text-center">
          Haz click sobre los productos que quieres ver
        </p>
        <img
          src={
            recursosUrlState + "/img/Index/web/botones/flecha-grande-abajo.webp"
          }
          alt="Señalacion"
          className="flechaGrandeAbajo"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              recursosUrlState +
              "/img/Index/web/botones/flecha-grande-abajo.png";
          }}
        />
      </div> */}
      <CarouselInfo images={images} />

      <div>
        <hr style={{ marginTop: "0" }}/>
        <HandlePage
          headerState={headerState}
        />
        <hr />
      </div>
            
      <div className="Bar" style={data.BLUSAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barBlusas}
          onClick={() =>
            actualizarLista(
              "listaBlusas",
              "6583763e8dfd4f911a2d0388",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barBlusas
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/camisetaMujer.webp"}
            className="imagenCategoria"
            alt="Categoria Camisetas Mujer"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/camisetaMujer.png";
            }}
          />
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Blusas</p>
          {data.BLUSAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.BLUSAS.Descuento > 0 ? (
            <p className="Descuento">{data.BLUSAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaBlusas">
          <ListaProductos
            estadoBar={estadoBar["listaBlusas"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>

      <div className="Bar" style={data.TOPS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barTops}
          onClick={() =>
            actualizarLista(
              "listaTops",
              "6584546244954018ca00865e",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barTops
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/blusa.webp"}
            className="imagenCategoria"
            alt="Categoria Blusas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/blusa.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Tops</p>
          {data.TOPS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.TOPS.Descuento > 0 ? (
            <p className="Descuento">{data.TOPS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaTops">
          <ListaProductos
            estadoBar={estadoBar["listaTops"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>

      <div className="Bar" style={data.SETS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barSets}
          onClick={() =>
            actualizarLista(
              "listaSets",
              "6584551544954018ca008661",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barSets
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/vestido.webp"}
            className="imagenCategoria"
            alt="Categoria Vestidos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/vestido.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Sets</p>
          {data.SETS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.SETS.Descuento > 0 ? (
            <p className="Descuento">{data.SETS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaSets">
          <ListaProductos
            estadoBar={estadoBar["listaSets"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>

      <div className="Bar" style={data.LYCRAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barLycras}
          onClick={() =>
            actualizarLista(
              "listaLycras",
              "6525a7906b44bb8bf3323210",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barLycras
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/lycra.webp"}
            className="imagenCategoria"
            alt="Categoria Lycras"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/lycra.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Lycras</p>
          {data.LYCRAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.LYCRAS.Descuento > 0 ? (
            <p className="Descuento">{data.LYCRAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaLycras">
          <ListaProductos
            estadoBar={estadoBar["listaLycras"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>

      <div className="Bar" style={data.VESTIDOS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barVestidos}
          onClick={() =>
            actualizarLista(
              "listaVestidos",
              "65578d4701bf5211b63d14bc",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barVestidos
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/vestido.webp"}
            className="imagenCategoria"
            alt="Categoria Vestidos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/vestido.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Vestidos</p>
          {data.VESTIDOS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.VESTIDOS.Descuento > 0 ? (
            <p className="Descuento">{data.VESTIDOS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaVestidos">
          <ListaProductos
            estadoBar={estadoBar["listaVestidos"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>

      <div className="Bar" style={data.ENTERIZOS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barEnterizos}
          onClick={() =>
            actualizarLista(
              "listaEnterizos",
              "65f075f9f379953d314aeca3",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barEnterizos
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/body.webp"}
            className="imagenCategoria"
            alt="Categoria Bodys"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/body.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Enterizo</p>
          {data.ENTERIZOS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.ENTERIZOS.Descuento > 0 ? (
            <p className="Descuento">{data.ENTERIZOS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaEnterizos">
          <ListaProductos
            estadoBar={estadoBar["listaEnterizos"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>
      
      <div className="Bar" style={data.BODYS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barBodys}
          onClick={() =>
            actualizarLista(
              "listaBodys",
              "655bad5a1cdc932b9e974a01",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barBodys
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/body.webp"}
            className="imagenCategoria"
            alt="Categoria Bodys"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/body.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Bodys</p>
          {data.BODYS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.BODYS.Descuento > 0 ? (
            <p className="Descuento">{data.BODYS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaBodys">
          <ListaProductos
            estadoBar={estadoBar["listaBodys"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div>
      
      <div className="Bar" style={data.ROPAINTERIOR.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barRopaInterior}
          onClick={() =>
            actualizarLista(
              "listaRopaInterior",
              "65f06ae2f379953d314aeca2",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barRopaInterior
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/pantalonetas.webp"}
            className="imagenCategoria"
            alt="Categoria Pantalonetas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/pantalonetas.png";
            }}
          ></img> 
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Ropa Interior</p>
          {data.ROPAINTERIOR.Nuevo ? (
            <p className="Descuento" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.ROPAINTERIOR.Descuento > 0 ? (
            <p className="Descuento">{data.ROPAINTERIOR.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaRopaInterior">
          <ListaProductos
            estadoBar={estadoBar["listaRopaInterior"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>

      {/* <div className="Bar">
        <div
          className="barLinea"
          ref={barDescuentos}
          onClick={() =>
            actualizarLista(
              "listaDescuentos",
              "Descuentos",
              null,
              "hombre",
              null,
              null,
              null,
              barDescuentos
            )
          }
        >
          {/* <img
            src={"https://hosting.basic.dekada.co/BasicCouture/img/Categorias/descuento.png"}
            className="imagenCategoria"
            alt="CategoriaDescuentos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Header/logos/logo-negro.webp";
            }}
          />
          <div className="vertical-line"></div> *
          <p className="categoriaBar">Rebajas</p>
          <p className="Descuento">20% OFF</p>
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaDescuentos" style={{ marginBottom: "50px" }}>
          <ListaProductos
            estadoBar={estadoBar["listaDescuentos"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            productoCompra={productoCompra}
            setProductoCompra={setProductoCompra}
            activado={activado}
            setActivado={setActivado}
            productosByRef={productosByRef}
            carrito={carrito}
            // clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
            vendedor={vendedor}
            modificarVendedorState={modificarVendedorState}
            Bodega={Bodega}
            setBodega={setBodega}
          />
        </div>
      </div> */}
      <Footer></Footer>
    </div>
    </>
  );
};
export default Mayoristas;
